import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from '../assets/logo/logo.png';

const Login = () => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const email = e.target.elements.email.value;
        const password = e.target.elements.password.value;
        const isDoctor = e.target.elements.doctor.value === "yes";
    
        console.log("Email:", email);
        console.log("Password:", password);
    
        try {
            const endpoint = isDoctor ? 'https://qrmedisync-mdrg.onrender.com/doctor' : 'https://qrmedisync-mdrg.onrender.com/patients';
            const response = await axios.get(endpoint);
            const users = response.data;
            console.log("Users:", users);
    
            const user = users.find(user => user.email === email && user.password === password);
    
            console.log("User:", user);
    
            if (user) {    
                // Navigate to DiscoverWorkers and pass user data as state
                navigate(isDoctor ? '/doctorDashboard' : '/patientDashboard', { state: { user } });
            } else {
                setError('Incorrect email or password');
                toast.error('Incorrect email or password');
            }
        } catch (error) {
            console.error('Error fetching users:', error);
            setError('An error occurred. Please try again later.');
            toast.error('An error occurred. Please try again later.');
        }
    };
    return (
        <main className="w-full flex">
            <ToastContainer />
            <div className="relative flex-1 hidden items-center justify-center h-screen bg-blue-500 lg:flex">
                
            </div>
            <div className="flex-1 flex items-center justify-center h-screen">
                <div className="w-full max-w-md space-y-8 px-4 text-gray-700 sm:px-0">
                    <div className="">
                        <img src={Logo} alt="Logo" width={150} className="lg:hidden" />
                        <div className="mt-5 space-y-2">
                            <h3 className="text-gray-700 text-2xl font-bold sm:text-3xl">Sign in</h3>
                            <p className="">Not have an account? <a href="javascript:void(0)" onClick={() => navigate('/signup')} className="font-medium text-blue-500 hover:text-blue-300">Create an account</a></p>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit} className="space-y-5">
                        <div>
                            <label className="font-medium">Email</label>
                            <input
                                name="email"
                                type="email"
                                required
                                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-blue-500 shadow-sm rounded-lg"
                            />
                        </div>
                        <div>
                            <label className="font-medium">Password</label>
                            <input
                                name="password"
                                type="password"
                                required
                                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-blue-500 shadow-sm rounded-lg"
                            />
                        </div>
                        <div className="flex items-center space-x-2">
  <p>Are you Doctor?</p>
  <label className="flex items-center space-x-1">
    <input type="radio" name="doctor" value="yes" className="form-radio h-5 w-5 text-blue-600" />
    <span className="text-gray-700">Yes</span>
  </label>
  <label className="flex items-center space-x-1">
    <input type="radio" name="doctor" value="no" className="form-radio h-5 w-5 text-blue-600" />
    <span className="text-gray-700">No</span>
  </label>
</div>
                        <button
                            type="submit"
                            className="w-full px-4 py-2 text-white font-medium bg-blue-500 hover:bg-blue-300 active:bg-blue-300 rounded-lg duration-150"
                        >
                            Log in
                        </button>
                    </form>
                   
                </div>
            </div>
        </main>
    );
}

export default Login;
