import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import QRCode from 'react-qr-code';

const PrescriptionForm = ({ patientData }) => {
  if (!patientData) {
    return <div>Loading...</div>; // or any other error handling mechanism
  }

  const { name, age, gender, email, phone } = patientData; // Destructuring patientData directly

  // Assuming appointment data will be dynamically fetched or received from props in a real application
  const appointment = patientData.appointments ? patientData.appointments[0] : null;

  // Generate prescription data
  let prescriptionData = '';
  if (appointment) {
    prescriptionData = appointment.prescriptions.map(prescription =>
      `${prescription.medicineName}, Dosage: ${prescription.dosage}, Frequency: ${prescription.frequency}`
    ).join('\n');
  }

  return (
    <div className="printable-area max-w-lg mx-auto bg-white shadow-md rounded p-8 relative">
      <div className="qr-code-container">
        {/* QR Code */}
        <QRCode value={prescriptionData} size={150} />
      </div>

      <div className="prescription-content">
        <h2 className="text-2xl font-bold mb-2">Doctor Prescription</h2>
        <div className="flex justify-between">
          <div>
            <p className="text-sm font-semibold">Patient Name:</p>
            <p className="text-lg">{name}</p>
          </div>
          <div>
            <p className="text-sm font-semibold">Age:</p>
            <p className="text-lg">{age}</p>
          </div>
          <div>
            <p className="text-sm font-semibold">Gender:</p>
            <p className="text-lg">{gender}</p>
          </div>
        </div>
        <div className="flex justify-between">
          <div>
            <p className="text-sm font-semibold">Email:</p>
            <p className="text-lg">{email}</p>
          </div>
          <div>
            <p className="text-sm font-semibold">Phone:</p>
            <p className="text-lg">{phone}</p>
          </div>
        </div>
        <div>
              <h3 className="text-lg font-semibold mb-2">Appointment Details</h3>
              <p className="text-sm"><span className="font-semibold">Date:</span> {new Date(appointment.appointmentDate).toLocaleString()}</p>
              <p className="text-sm"><span className="font-semibold">Category:</span> {appointment.diseaseCategory}</p>
              <p className="text-sm"><span className="font-semibold">Description:</span> {appointment.diseaseDescription}</p>
              <p className="text-sm"><span className="font-semibold">Period:</span> {appointment.diseasePeriod}</p>
            </div>
        {appointment && (
          <>
            <div className="mb-4">
              <h3 className="text-lg font-semibold mb-2">Prescriptions</h3>
              <ul>
                {appointment.prescriptions.map((prescription, index) => (
                  <li key={index} className="mb-2">
                    <p className="text-lg">{prescription.medicineName}</p>
                    <p className="text-sm">Dosage: {prescription.dosage}</p>
                    <p className="text-sm">Frequency: {prescription.frequency}</p>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-2">Recommendation</h3>
              <p className="text-lg">{appointment.recommendation}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const PrintablePrescriptionForm = ({ patientData }) => {
  const printableRef = useRef();

  const printPrescription = () => {
    const printableArea = printableRef.current.innerHTML;
    const originalDocument = document.body.innerHTML;
    document.body.innerHTML = printableArea;
    window.print();
    document.body.innerHTML = originalDocument;
  };

  return (
    <>
    <div>
      <button className="mb-4" onClick={printPrescription}>Print<span> </span>
        <FontAwesomeIcon icon={faFilePdf} className="text-2xl text-red-500" />
      </button>
      <div ref={printableRef}>
        <PrescriptionForm patientData={patientData} />
      </div>
      </div>
    </>
  );
};

export default PrintablePrescriptionForm;
