import React, { useState, useEffect } from 'react';
import { BarChart, PieChart, Pie, XAxis, YAxis, Tooltip, Legend, CartesianGrid, Bar } from 'recharts';
import axios from 'axios';

const PatientAnalysis = () => {
  const [patientsData, setPatientsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://qrmedisync-mdrg.onrender.com/patients');
        setPatientsData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Calculate gender distribution
  const genderData = patientsData.reduce((acc, patient) => {
    acc[patient.gender] = (acc[patient.gender] || 0) + 1;
    return acc;
  }, {});

  // Calculate status distribution
  const statusData = patientsData.reduce((acc, patient) => {
    acc[patient.status] = (acc[patient.status] || 0) + 1;
    return acc;
  }, {});

  // Calculate disease category distribution
  const diseaseCategoryData = patientsData.reduce((acc, patient) => {
    patient.appointments.forEach(appointment => {
      acc[appointment.diseaseCategory] = (acc[appointment.diseaseCategory] || 0) + 1;
    });
    return acc;
  }, {});

  // Custom colors for pie charts
  const pieChartColors = ['#0d96ff', '#22d3ee', '#6fffe9', '#4b5563', '#cbd5e0'];

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-semibold mb-8">Patient Analysis</h1>
      <div className="flex flex-wrap justify-around">
        <div className="w-full md:w-1/3 mb-8">
          <h2 className="text-xl font-semibold mb-4">Gender Distribution</h2>
          <PieChart width={400} height={300}>
            <Pie dataKey="value" isAnimationActive={false} data={Object.entries(genderData).map(([gender, value], index) => ({ name: gender, value, fill: pieChartColors[index] }))} cx={200} cy={150} outerRadius={80} label />
            <Tooltip />
          </PieChart>
          <p className="mt-4">Conclusion: Predominance of {Object.keys(genderData)[0]} gender.</p>
        </div>
        <div className="w-full md:w-1/3 mb-8">
          <h2 className="text-xl font-semibold mb-4">Status Distribution</h2>
          <BarChart width={400} height={300} data={Object.entries(statusData).map(([status, value], index) => ({ name: status, value, fill: pieChartColors[index] }))}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="value" />
          </BarChart>
          <p className="mt-4">Conclusion: Higher number of {Object.keys(statusData)[0]} patients.</p>
        </div>
        <div className="w-full md:w-1/3 mb-8">
          <h2 className="text-xl font-semibold mb-4">Disease Category Distribution</h2>
          <PieChart width={400} height={300}>
            <Pie dataKey="value" isAnimationActive={false} data={Object.entries(diseaseCategoryData).map(([category, value], index) => ({ name: category, value, fill: pieChartColors[index] }))} cx={200} cy={150} outerRadius={80} label />
            <Tooltip />
          </PieChart>
          <p className="mt-4">Conclusion: Most cases in {Object.keys(diseaseCategoryData)[0]}.</p>
        </div>
      </div>
    </div>
  );
};

export default PatientAnalysis;
