import React from 'react';

export default function PatientStatus({ user }) {
  const isActive = user.status === 'active';

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 py-6 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white p-6 rounded-xl shadow-md">
        <div className="text-center">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">{user.name}</h2>
          <p className="mt-2 text-center text-sm text-gray-600">{user.age} years old, {user.gender}</p>
          <p className={`mt-2 text-center text-sm ${isActive ? 'text-green-600' : 'text-red-600'}`}>
            {isActive ? 'Active' : 'Inactive'}
          </p>
        </div>
        <div className="mt-8 space-y-6">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Latest Appointments:</h3>
            <ul className="mt-2 space-y-2">
              {user.appointments.map(appointment => {
                const date = new Date(appointment.appointmentDate);
                const formattedDate = `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
                return (
                  <li key={appointment._id} className="text-sm text-gray-600">
                    <span className="font-medium">{formattedDate}</span> - {appointment.diseaseDescription}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}