import React, { useEffect, useState } from "react";
import DocDashboardPatientDetails from "./DocDashboardPatientDetails";

export default function DocDashbordPatientList() {
  const [showDetails, setShowDetails] = useState(false);
  const [patients, setPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [appointmentControl, setAppointmentControl] = useState('Stop Appointments');

  useEffect(() => {
    fetchPatients();
  }, []);

  const fetchPatients = async () => {
    try {
      const response = await fetch('https://qrmedisync-mdrg.onrender.com/patients/');
      if (!response.ok) {
        throw new Error('Failed to fetch patients data');
      }
      const data = await response.json();
      setPatients(data);
    } catch (error) {
      console.error('Error fetching patients:', error);
    }
  };

  const toggleShowDetails = () => {
    setShowDetails(prevShow => !prevShow);
  };

  const toggleAppointmentControl = () => {
    setAppointmentControl(prevText => prevText === 'Stop Appointments' ? 'Start Appointments' : 'Stop Appointments');
  };

  const handleDiagnose = (patient) => {
    setSelectedPatient(patient);
    toggleShowDetails();
  };

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div className="max-w-screen-xl mx-auto mt-10 px-4 md:px-8">
      <div className="items-start justify-between md:flex">
        <div className="max-w-lg">
          <h3 className="text-gray-800 text-xl font-bold sm:text-2xl">
            Team members
          </h3>
          <p className="text-gray-600 mt-2">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p>
        </div>
        <button class="flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-indigo-600 rounded-lg hover:bg-indigo-500 focus:outline-none focus:ring focus:ring-indigo-300 focus:ring-opacity-80"onClick={()=>refreshPage() } >
    <svg class="w-5 h-5 mx-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clip-rule="evenodd" />
    </svg>

    <span class="mx-1">Refresh</span>
</button>
        <div className="mt-3 md:mt-0">
          <button
            onClick={toggleAppointmentControl}
            className={`flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform  rounded-lg focus:outline-none focus:ring focus:ring-indigo-300 focus:ring-opacity-80 ${appointmentControl === 'Stop Appointments' ? 'bg-red-600' : 'bg-green-600'}`}
          >
            {appointmentControl}
          </button>
        </div>
      </div>
      {showDetails && <DocDashboardPatientDetails patientData={selectedPatient} />}
      {!showDetails && (
        <div className="mt-12 shadow-sm border rounded-lg overflow-x-auto">
          <table className="w-full table-auto text-sm text-left">
            <thead className="text-gray-600 font-medium border-b">
              <tr>
                <th className="py-3 px-6 flex items-center gap-x-4">
                  Patient Name
                </th>
                <th className="py-3 px-6">Disease Category</th>
                <th className="py-3 px-6">Age</th>
                <th className="py-3 px-6">Gender</th>
                <th className="py-3 px-6"></th>
              </tr>
            </thead>
            <tbody className="text-gray-600 divide-y">
              {patients.map((patient, idx) => (
                <tr key={idx} className={idx % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                  <td className="px-6 py-4 whitespace-nowrap flex items-center gap-x-4">
                    {patient.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{patient.appointments[0].diseaseCategory}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{patient.age}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{patient.gender}</td>
                  <td className="text-right px-6 whitespace-nowrap">
                    <button
                      className="py-2 px-3 font-medium text-indigo-600 hover:text-indigo-500 duration-150 hover:bg-gray-50 rounded-lg"
                      onClick={() => handleDiagnose(patient)}
                    >
                      Diagnose
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
