import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faPrescriptionBottle, faChartLine, faMedkit, faVideo, faShieldAlt, faComments } from '@fortawesome/free-solid-svg-icons';

const featuresData = [
  {
    title: "Appointment Scheduling and Management",
    description: "Effortlessly navigate your healthcare journey through our user-friendly platform. Schedule and manage appointments with your preferred doctors, ensuring a seamless and personalized experience tailored to your convenience.",
    svg: <FontAwesomeIcon icon={faCalendarAlt} />
  },
  {
    title: "Digital Prescriptions and Secure QR Codes",
    description: "Embrace the future of prescription management with our digital solutions. Receive secure QR codes for prescribed medications, streamlining the dispensation process and ensuring the security of your medical information.",
    svg: <FontAwesomeIcon icon={faPrescriptionBottle} />
  },
  {
    title: "Real-Time Analytics Dashboard",
    description: "Empower yourself with insights through our real-time analytics dashboard. Track your appointments, prescription history, and medication trends, allowing you to make informed decisions about your health and well-being.",
    svg: <FontAwesomeIcon icon={faChartLine} />
  },
  {
    title: "Integrated QR Code Drug ATM System",
    description: "Experience a revolutionary approach to medication dispensation with our integrated QR Code Drug ATM system. Scan your prescription QR code for instant, accurate, and queue-free dispensation, revolutionizing the way you access medications.",
    svg: <FontAwesomeIcon icon={faMedkit} />
  },
  {
    title: "Telemedicine Integration (Coming Soon)",
    description: "Stay connected with healthcare professionals from the comfort of your home. Our upcoming telemedicine integration will provide you with access to remote healthcare services, ensuring continuity of care.",
    svg: <FontAwesomeIcon icon={faVideo} />
  },
  {
    title: "Security and Compliance",
    description: "Trust in the security of your health information. Our system employs robust data encryption and complies with HIPAA regulations, prioritizing the confidentiality and privacy of your medical data.",
    svg: <FontAwesomeIcon icon={faShieldAlt} />
  },
  {
    title: "User Feedback and Support",
    description: "Your feedback matters. Share your insights with us through our user-friendly feedback system. Our dedicated support team is ready to assist you, ensuring a smooth and responsive experience with our services.",
    svg: <FontAwesomeIcon icon={faComments} />
  },
];


export default function Features() {
  return (
    <>
      {/* Component */}
      <link
        rel="stylesheet"
        href="https://cdn.tailgrids.com/tailgrids-fallback.css"
      />
      {/* Services Section Start */}
      <section id='services' className="pt-20 lg:pt-[120px] pb-12 lg:pb-[90px]">
        <div className="container">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full px-4">
              <div className="text-center mx-auto mb-12 lg:mb-20 max-w-[510px]">
                <span className="font-semibold text-lg text-primary mb-2 block">
                  Our Services
                </span>
                <h2 className="font-bold text-3xl sm:text-4xl md:text-[40px] text-dark mb-4">
                  What We Offer
                </h2>
                <p className="text-base text-body-color">
                  There are many variations of passages of Lorem Ipsum
                  available but the majority have suffered alteration in some
                  form.
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap -mx-4">
            {featuresData.map((feature, index) => (
              <div key={index} className="w-full md:w-1/2 lg:w-1/3 px-4">
                <div className="p-10 md:px-7 xl:px-10 rounded-[20px] bg-white shadow-md hover:shadow-lg mb-8">
                  <div className="w-[70px] h-[70px] flex items-center justify-center bg-primary rounded-2xl mb-8 text-white">
                    {feature.svg}
                  </div>
                  <h4 className="font-semibold text-xl text-dark mb-3">
                    {feature.title}
                  </h4>
                  <p className="text-body-color">
                    {feature.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
