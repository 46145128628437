import React, { useState } from "react";
import axios from "axios";
import DocDashbordPatientList from "./DocDashboardPatientList";
import { ToastContainer, toast } from "react-toastify";
import PrescriptionForm from "./PrescriptionForm";

export default function DocDashboardPatientDetails({ patientData }) {
  console.log("Patient ID 1" + patientData._id);
  const [dosage, setDosage] = useState("");
  const [frequency, setFrequency] = useState("");
  const [message, setMessage] = useState("");
  const [recommendation, setRecommendation] = useState("");
  const [showImage, setShowImage] = useState(false);

  const toggleShowImage = () => {
    setShowImage((prevShow) => !prevShow);
  };

  // write a function to render the patient list
  function renderPatientList() {
    //refresh the page
    window.location.reload();
  }

  const [medicineName, setMedicineName] = useState(""); // State to hold the medicine name input
  const [recommendedMedicines, setRecommendedMedicines] = useState([]); // State to hold recommended medicines

  // Function to handle input change for medicine name
  const handleMedicineNameChange = async (event) => {
    const inputValue = event.target.value;
    setMedicineName(inputValue);
    // Call the API to get recommended medicines based on the input
    fetchRecommendedMedicines(inputValue);
  };

  // Function to fetch recommended medicines from the API
  const fetchRecommendedMedicines = async (query) => {
    try {
      // Make a POST request to the API
      const response = await axios.post(
        "https://search.apollo247.com/fullSearch",
        {
          query: query,
          selSortBy: "relevance",
          pincode: "",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Oeu324WMvfKOj5KMJh2Lkf00eW1",
          },
        }
      );
      // Extract recommended medicines from the response data
      const recommendedMedicines = response.data.data.products.map(
        (product) => product.name
      );
      // Update state with recommended medicines
      setRecommendedMedicines(recommendedMedicines);
    } catch (error) {
      console.error("Error fetching recommended medicines:", error);
    }
  };

  // Function to handle selection of recommended medicine
  const handleRecommendedMedicineClick = (medicine) => {
    // Set the selected medicine as the value of the medicineName input
    setMedicineName(medicine);
    // Clear recommended medicines list
    setRecommendedMedicines([]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const prescriptionData = {
      // Assuming medicineName, dosage, and frequency are obtained from form fields
      medicineName: medicineName,
      dosage: dosage,
      frequency: frequency,
    };

    try {
      const response = await fetch(
        `https://qrmedisync-mdrg.onrender.com/patients/${patientData._id}/prescriptions`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify([prescriptionData]), // Convert prescriptionData to an array
        }
      );

      if (response.ok) {
        await UpdatePatientStatus(event);
        toast.success("Prescription associated with the patient successfully.");
        setMessage("Prescription associated with the patient successfully.");
        // Clear form fields
        setMedicineName("");
        setDosage("");
        setFrequency("");
      } else {
        const errorMessage = await response.text();
        toast.error(
          "Failed to associate prescription with the patient: " + errorMessage
        );
        setMessage(
          "Failed to associate prescription with the patient: " + errorMessage
        );
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setMessage("An error occurred. Please try again.");
    }
  };

const UpdatePatientStatus = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(
        `https://qrmedisync-mdrg.onrender.com/patients/${patientData._id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ status: "deactive" }),
        }
      );

      if (response.ok) {
        toast.success("Patient status updated successfully.");
        setMessage("Patient status updated successfully.");
      } else {
        const errorMessage = await response.text();
        toast.error("Failed to update patient status: " + errorMessage);
        setMessage("Failed to update patient status: " + errorMessage);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setMessage("An error occurred. Please try again.");
    }
}
  


  return (
    <>
      <div className="max-w-screen-xl mx-auto mt-10 px-4 md:px-8">
        <div className="shadow-sm border rounded-lg overflow-x-auto">
          <button
            type="button"
            onClick={renderPatientList}
            class="w-full flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:bg-gray-100 dark:text-gray-200 "
          >
            <svg
              class="w-5 h-5 rtl:rotate-180"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
              />
            </svg>
            <span>Go back</span>
          </button>
          {/* Content */}
          <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
            {/* Patient Information Section */}
            <div className="mb-8">
              <h2 className="text-lg font-semibold text-gray-800 mb-4">
                Patient Information
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="text-sm font-medium text-gray-600">
                    Patient Name
                  </label>
                  <p className="text-base text-gray-800">{patientData.name}</p>
                </div>
                <div>
                  <label className="text-sm font-medium text-gray-600">
                    Age
                  </label>
                  <p className="text-base text-gray-800">{patientData.age}</p>
                </div>
                <div>
                  <label className="text-sm font-medium text-gray-600">
                    Gender
                  </label>
                  <p className="text-base text-gray-800">
                    {patientData.gender}
                  </p>
                </div>
                <div>
                  <label className="text-sm font-medium text-gray-600">
                    Email
                  </label>
                  <p className="text-base text-gray-800">{patientData.email}</p>
                </div>
                <div>
                  <label className="text-sm font-medium text-gray-600">
                    Phone
                  </label>
                  <p className="text-base text-gray-800">{patientData.phone}</p>
                </div>
                <div>
                  <label className="text-sm font-medium text-gray-600">
                    Appointment Date
                  </label>
                  <p className="text-base text-gray-800">
                    {patientData.appointments[0].appointmentDate}
                  </p>
                </div>
                <div className="col-span-2">
                  <label className="text-sm font-medium text-gray-600">
                    Disease Category
                  </label>
                  <p className="text-base text-gray-800">
                    {patientData.appointments[0].diseaseCategory}
                  </p>
                </div>
                <div className="col-span-2">
                  <label className="text-sm font-medium text-gray-600">
                    Disease Description
                  </label>
                  <p className="text-base text-gray-800">
                    {patientData.appointments[0].diseaseDescription}
                  </p>
                </div>
              </div>
            </div>

            {/* Prescription Section */}
            <div className="md:flex md:items-start">
              {/* Prescription Form */}
              <form
                onSubmit={handleSubmit}
                className="md:w-1/2 md:mr-4 mb-4 md:mb-0"
              >
                <div className="bg-white  px-8 pt-6 pb-8">
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="medicineName"
                    >
                      Medicine Name
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="medicineName"
                      type="text"
                      placeholder="Enter Medicine Name"
                      required
                      value={medicineName}
                      onChange={handleMedicineNameChange}
                    />
                  </div>

                  <div>
                    {recommendedMedicines.length > 0 && (
                      <ul>
                        {recommendedMedicines.map((medicine, index) => (
                          <li key={index}>
                            <button
                              onClick={() =>
                                handleRecommendedMedicineClick(medicine)
                              }
                              className="text-blue-500 hover:text-blue-700"
                            >
                              {medicine}
                            </button>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="dosage"
                    >
                      Dosage
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="dosage"
                      type="text"
                      value={dosage}
                      onChange={(e) => setDosage(e.target.value)}
                      required
                      placeholder="Enter Dosage"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="frequency"
                    >
                      Frequency
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="frequency"
                      type="text"
                      placeholder="Enter Frequency"
                      value={frequency}
                      onChange={(e) => setFrequency(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="recommendation"
                    >
                      Recommendation
                    </label>
                    <textarea
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="recommendation"
                      placeholder="Enter Recommendation"
                      value={recommendation}
                      onChange={(e) => setRecommendation(e.target.value)}
                      required
                    ></textarea>
                  </div>
                  <div className="flex items-center justify-between">
                    <button
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                  <div> {message && <p>{message}</p>}</div>
                </div>
              </form>

              {/* Image Section */}
              <div className="md:w-1/2">
                <div className="flex items-center justify-center h-full w-full">
                  {!showImage && (
                    <button
                      onClick={toggleShowImage}
                      className="py-2 px-4 bg-blue-500 text-white rounded"
                    >
                      Get Prescription
                    </button>
                  )}
                    {showImage && <PrescriptionForm patientData={patientData} />}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
