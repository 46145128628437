import React from 'react'
import about from "../assets/about.png";
export default function About() {
  return (
    <section id='about' className="flex items-center py-10 bg-stone-100 xl:h-screen font-poppins dark:bg-gray-800 ">
    <div className="justify-center flex-1 max-w-6xl py-4 mx-auto lg:py-6 md:px-6">
      <div className="flex flex-wrap ">
        <div className="w-full px-4 mb-10 lg:w-1/2 lg:mb-0">
          <div className="relative">
            <img
              src={about}
              alt=""
              className="relative z-20 object-cover w-full h-96 lg:rounded-tr-[80px] lg:rounded-bl-[80px] rounded"
            />
            <div className="absolute z-10 hidden w-full h-full bg-blue-400 rounded-bl-[80px] rounded -bottom-6 right-6 lg:block"></div>
            <div className="absolute z-50 text-blue-400 transform -translate-y-1/2 cursor-pointer top-1/2 left-[46%] hover:text-blue-500">
          
            </div>
          </div>
        </div>
        <div className="w-full px-4 mb-10 lg:w-1/2 lg:mb-0 ">
          <div className="relative">
            <h1 className="absolute -top-20   left-0 text-[20px] lg:text-[100px] text-gray-900 font-bold  dark:text-gray-200 opacity-5 md:block hidden">
              About Us
            </h1>
            <h1 className="pl-2 text-3xl font-bold border-l-8 border-blue-400 md:text-5xl dark:text-white">
              Welcome to our site
            </h1>
          </div>
          <p className="mt-6 mb-10 text-base leading-7 text-gray-500 dark:text-gray-400">
          I'm Mahsook, a prefinal year engineering student, and the mind behind the development of this transformative solution. With a passion for leveraging technology to enhance healthcare experiences, I embarked on creating a platform that simplifies appointment scheduling, embraces digital prescriptions, and integrates cutting-edge solutions like the QR Code Drug ATM. Our mission is to revolutionize the way healthcare is accessed and delivered. We strive to provide seamless, secure, and user-friendly services that prioritize your well-being. Join us on this journey towards a future where healthcare is efficient, accessible, and
          </p>
          <a
            href="#"
            className="px-4 py-3 text-gray-50 transition-all transform bg-blue-400 rounded-[80px] hover:bg-blue-500 dark:hover:text-gray-100 dark:text-gray-100 "
          >
            Learn more
          </a>
        </div>
      </div>
    </div>
  </section>
  
  )
}
