// Home.js
import React, { useRef } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import Hero from "../components/Hero";
import Features from "../components/Features";
import Services from "../components/Services";
import About from "../components/About";
import Contact from "../components/Contact";
import PatientFooter from "../components/PatientFooter";

export default function Home() {
  const aboutRef = useRef(null); // Define ref for About section
  const servicesRef = useRef(null); // Define ref for Services section
  const contactRef = useRef(null); // Define ref for Contact section

  return (
    <>
      <Navbar 
        aboutRef={aboutRef} 
        servicesRef={servicesRef} 
        contactRef={contactRef}
      />
      <Hero />
      <About ref={aboutRef} /> {/* Pass ref to About component */}
      <Features />
      <Services ref={servicesRef} /> {/* Pass ref to Services component */}
      <Contact ref={contactRef} /> {/* Pass ref to Contact component */}
      <PatientFooter />
    </>
  );
}
