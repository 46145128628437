import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import PatientStatus from "../components/PatientStatus";
import PatientMedicine from "../components/PatientMedicine";
import PatientMedicalHistory from "../components/PatientMedicalHistory";
import PatientNavbar from "../components/PatientNavbar";
import BookAppointment from "../components/BookAppointment";

export default function PatientDashboard({ categoryId }) {
  const [selectedMenuItem, setSelectedMenuItem] = useState("Appointment Status");

  const location = useLocation();
  const user = location.state.user;

  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
  };
console.log("user", user);
  return (
    <>
      <PatientNavbar
        onMenuItemClick={handleMenuItemClick}
        selectedMenuItem={selectedMenuItem}
        user={user}
      />
      {selectedMenuItem === "Appointment Status" && <PatientStatus user={user} />}
      {selectedMenuItem === "Medical Shop" && <PatientMedicine user={user} />}
      {selectedMenuItem === "Medical History" && <PatientMedicalHistory user={user} />}
      {selectedMenuItem === "Book Appointment" && <BookAppointment user={user} />}
    </>
  );
}