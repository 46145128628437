// PatientNavbar.js
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import patient from "../assets/patients.jpg";
const AvatarMenu = () => {
  const [state, setState] = useState(false);
  const profileRef = useRef(null);
  const navigate = useNavigate();

  const logout = () => {
    navigate('/login');
  };

  useEffect(() => {
    const handleDropDown = (e) => {
      if (profileRef.current && !profileRef.current.contains(e.target)) {
        setState(false);
      }
    };

    document.addEventListener('click', handleDropDown);

    return () => {
      document.removeEventListener('click', handleDropDown);
    };
  }, []);

  return (
    <div className="relative border-t lg:border-none">
      <div className="">
        <button
          ref={profileRef}
          className="hidden w-10 h-10 outline-none rounded-full ring-offset-2 ring-gray-200 lg:focus:ring-2 lg:block"
          onClick={() => setState(!state)}
        >
          <img
            src={patient}
            className="w-full h-full rounded-full"
          />
        </button>
      </div>
      <ul className={`bg-white top-14 right-0 mt-6 space-y-6 lg:absolute lg:border lg:rounded-md lg:w-52 lg:shadow-md lg:space-y-0 lg:mt-0 ${state ? '' : 'lg:hidden'}`}>
        <li>
          <button className="block w-full text-justify text-gray-600 hover:text-gray-900 border-t py-3 lg:hover:bg-gray-50 lg:p-3" onClick={logout}>
            Logout
          </button>
        </li>
      </ul>
    </div>
  );
};

const PatientNavbar = ({ onMenuItemClick, selectedMenuItem }) => {
  const submenuNav = [
    { title: "Appointment Status", path: "javascript:void(0)" },
    { title: "Medical Shop", path: "javascript:void(0)" },
    { title: "Medical History", path: "javascript:void(0)" },
    { title: "Book Appointment", path: "javascript:void(0)" },
  ];

  const [state, setState] = useState(false);

  return (
    <>
      <header className="text-base lg:text-sm">
        <div className={`bg-white items-center gap-x-14 px-4 max-w-screen-xl mx-auto lg:flex lg:px-8 lg:static ${state ? "h-full fixed inset-x-0" : ""}`}>
          <div className="flex items-center justify-between py-3 lg:py-5 lg:block">
            <a href="javascript:void(0)">
              <img
                src="https://www.floatui.com/logo.svg"
                width={120}
                height={50}
                alt="Float UI logo"
              />
            </a>
            <div className="lg:hidden">
              <button className="text-gray-500 hover:text-gray-800" onClick={() => setState(!state)}>
                {state ? (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                    <path fillRule="evenodd" d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm8.25 5.25a.75.75 0 01.75-.75h8.25a.75.75 0 010 1.5H12a.75.75 0 01-.75-.75z" clipRule="evenodd" />
                  </svg>
                )}
              </button>
            </div>
          </div>
          <div className={`nav-menu flex-1 pb-28 mt-8 overflow-y-auto max-h-screen lg:block lg:overflow-visible lg:pb-0 lg:mt-0 ${state ? "" : "hidden"}`}>
            <ul className="items-center space-y-6 lg:flex lg:space-x-6 lg:space-y-0">
              <form onSubmit={(e) => e.preventDefault()} className='flex-1 items-center justify-start pb-4 lg:flex lg:pb-0'></form>
              <button className={`flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-indigo-600 rounded-lg hover:bg-indigo-500 focus:outline-none focus:ring focus:ring-indigo-300 focus:ring-opacity-80 ${selectedMenuItem === "Book Appointment" ? "bg-gray-100 text-gray-900" : ""}`} onClick={() => onMenuItemClick("Book Appointment")}>
                <span class="mx-1">Book Appointment</span>
              </button>
              <AvatarMenu />
            </ul>
          </div>
        </div>
      </header>
      <nav className="border-b">
        <ul className="flex items-center gap-x-3 max-w-screen-xl mx-auto px-4 overflow-x-auto lg:px-8">
          {submenuNav.map((item, idx) => (
            <li
              key={idx}
              className={`py-1 ${selectedMenuItem === item.title ? "bg-gray-100 text-gray-900" : ""}`}
              onClick={() => onMenuItemClick(item.title)}
            >
              <a href={item.path} className="block py-2 px-3 rounded-lg hover:text-gray-900 duration-150">
                {item.title}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
};

export default PatientNavbar;
