import React, { useState } from "react";

const Appointment = () => {
  const [formData, setFormData] = useState({
    "patient-name": "",
    "date-of-birth": "",
    "phone-number": "",
    "email-address": "",
    "preferred-appointment": "",
    "doctor-name": "",
    "reason-appointment": "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://qrmedisync-mdrg.onrender.com/appointment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Handle success, e.g., show a success message or redirect
        console.log("Appointment submitted successfully");
      } else {
        // Handle error, e.g., show an error message
        console.error("Appointment submission failed");
      }
    } catch (error) {
      console.error("Error submitting appointment:", error);
    }
  };

  return (
    <div className="bg-gray-200 min-h-screen py-16 font-roboto">
      <div className="container mx-auto">
        <div className="w-full max-w-2xl p-6 mx-auto bg-white rounded-md shadow-lg">
          <h2 className="text-2xl text-gray-900">Patient Information</h2>
          <form
            className="mt-6 border-t border-gray-400 pt-4"
            onSubmit={handleSubmit}
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Add your input fields here with onChange handlers */}
              {/* Example: */}
              <div className="mb-6">
                <label
                  htmlFor="patient-name"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Patient Name
                </label>
                <input
                  id="patient-name"
                  type="text"
                  placeholder="Enter patient name"
                  required
                  className="w-full bg-white text-gray-700 border border-gray-400 rounded-md py-2 px-4 focus:outline-none focus:border-gray-500"
                  value={formData["patient-name"]}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="date-of-birth"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Date of Birth
                </label>
                <input
                  id="date-of-birth"
                  type="date"
                  required
                  className="w-full bg-white text-gray-700 border border-gray-400 rounded-md py-2 px-4 focus:outline-none focus:border-gray-500"
                  value={formData["date-of-birth"]}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-6">
                <label
                  htmlFor="phone-number"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Phone Number
                </label>
                <input
                  id="phone-number"
                  type="tel"
                  placeholder="Enter phone number"
                  required
                  className="w-full bg-white text-gray-700 border border-gray-400 rounded-md py-2 px-4 focus:outline-none focus:border-gray-500"
                  value={formData["phone-number"]}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-6">
                <label
                  htmlFor="email-address"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Email Address
                </label>
                <input
                  id="email-address"
                  type="email"
                  placeholder="Enter email address"
                  required
                  className="w-full bg-white text-gray-700 border border-gray-400 rounded-md py-2 px-4 focus:outline-none focus:border-gray-500"
                  value={formData["email-address"]}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-6">
                <label
                  htmlFor="preferred-appointment"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Preferred Date and Time of Appointment
                </label>
                <input
                  id="preferred-appointment"
                  type="datetime-local"
                  className="w-full bg-white text-gray-700 border border-gray-400 rounded-md py-2 px-4 focus:outline-none focus:border-gray-500"
                  value={formData["preferred-appointment"]}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-6">
                <label
                  htmlFor="doctor-name"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Doctor Name (optional)
                </label>
                <input
                  id="doctor-name"
                  type="text"
                  className="w-full bg-white text-gray-700 border border-gray-400 rounded-md py-2 px-4 focus:outline-none focus:border-gray-500"
                  value={formData["doctor-name"]}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="mb-6">
              <label
                htmlFor="reason-appointment"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Reason for Appointment (optional)
              </label>
              <textarea
                id="reason-appointment"
                className="w-full bg-white text-gray-700 border border-gray-400 rounded-md py-2 px-4 focus:outline-none focus:border-gray-500"
                value={formData["reason-appointment"]}
                onChange={handleChange}
              />
            </div>

            <div className="flex justify-end mt-6">
              <button
                type="submit"
                className="px-4 py-2 bg-gray-900 text-white rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Appointment;
