import React, { useState, useEffect, useRef } from 'react';

export default function BookAppointment({ user }) {
    const [doctors, setDoctors] = useState([]);
    const [selectedDoctorId, setSelectedDoctorId] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [diseaseCategory, setDiseaseCategory] = useState('');
    const [diseasePeriod, setDiseasePeriod] = useState('');
    const [diseaseDescription, setDiseaseDescription] = useState('');
    const formRef = useRef(null);

    useEffect(() => {
        fetchDoctors();
    }, []);

    const fetchDoctors = () => {
        fetch('https://qrmedisync-mdrg.onrender.com/doctor')
            .then(response => response.json())
            .then(data => {
                setDoctors(data);
            })
            .catch(error => console.error('Error fetching doctors:', error));
    };

    const handleChange = event => {
        setSelectedDoctorId(event.target.value);
    };

    const handleSubmit = async event => {
        event.preventDefault();
        const form = formRef.current;
        const formData = new FormData(form);
        const data = {
            date,
            time,
            diseaseCategory,
            diseasePeriod,
            diseaseDescription,
            doctorId: selectedDoctorId,
            patientId: user.id
        };
    
        Object.entries(data).forEach(([key, value]) => {
            formData.append(key, value);
        });
    
        try {
            const doctorResponse = await fetch(`https://qrmedisync-mdrg.onrender.com/doctor/${selectedDoctorId}/appointments`, {
                method: 'POST',
                body: formData,
            });
            if (!doctorResponse.ok) {
                throw new Error('Error booking appointment with the doctor');
            }
            console.log('Appointment booked successfully with the doctor');
    
            // Update patient data if user id is available
            if (user && user.id) {
                const patientResponse = await fetch(`https://qrmedisync-mdrg.onrender.com/patients/${user.id}/appointments`, {
                    method: 'POST',
                    body: formData,
                });
                if (!patientResponse.ok) {
                    throw new Error('Error updating patient data');
                }
                console.log('Patient data updated successfully');
            }
    
            // Clear the form after successful submission
            form.reset();
        } catch (error) {
            console.error('Error booking appointment:', error);
            // Handle error, maybe show an error message to the user
        }
    };

    return (
        <div className="flex items-center justify-center p-12">
            <div className="mx-auto w-full max-w-[550px] bg-white">
                <form ref={formRef} onSubmit={handleSubmit}>
                    <div className="mb-5">
                        <label
                            htmlFor="doctor"
                            className="mb-3 block text-base font-medium text-[#07074D]"
                        >
                            Select Doctor
                        </label>
                        <select
                            name="doctor"
                            id="doctor"
                            className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                            value={selectedDoctorId}
                            onChange={handleChange}
                        >
                            <option value="">Select a doctor</option>
                            {doctors.map(doctor => (
                                <option key={doctor._id} value={doctor._id}>
                                    {doctor.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="-mx-3 flex flex-wrap">
                        <div className="w-full px-3 sm:w-1/2">
                            <div className="mb-5">
                                <label
                                    htmlFor="date"
                                    className="mb-3 block text-base font-medium text-[#07074D]"
                                >
                                    Date
                                </label>
                                <input
                                    type="date"
                                    name="date"
                                    id="date"
                                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                                    value={date}
                                    onChange={e => setDate(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="w-full px-3 sm:w-1/2">
                            <div className="mb-5">
                                <label
                                    htmlFor="time"
                                    className="mb-3 block text-base font-medium text-[#07074D]"
                                >
                                    Time
                                </label>
                                <input
                                    type="time"
                                    name="time"
                                    id="time"
                                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                                    value={time}
                                    onChange={e => setTime(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mb-5">
                        <label
                            htmlFor="diseaseCategory"
                            className="mb-3 block text-base font-medium text-[#07074D]"
                        >
                            Disease Category
                        </label>
                        <input
                            type="text"
                            name="diseaseCategory"
                            id="diseaseCategory"
                            placeholder="Disease Category"
                            className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                            value={diseaseCategory}
                            onChange={e => setDiseaseCategory(e.target.value)}
                        />
                    </div>
                    <div className="mb-5">
                        <label
                            htmlFor="diseasePeriod"
                            className="mb-3 block text-base font-medium text-[#07074D]"
                        >
                            Disease Period
                        </label>
                        <input
                            type="text"
                            name="diseasePeriod"
                            id="diseasePeriod"
                            placeholder="Disease Period"
                            className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                            value={diseasePeriod}
                            onChange={e => setDiseasePeriod(e.target.value)}
                        />
                    </div>
                    <div className="mb-5">
                        <label
                            htmlFor="diseaseDescription"
                            className="mb-3 block text-base font-medium text-[#07074D]"
                        >
                            Disease Description
                        </label>
                        <input
                            type="text"
                            name="diseaseDescription"
                            id="diseaseDescription"
                            placeholder="Disease Description"
                            className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                            value={diseaseDescription}
                            onChange={e => setDiseaseDescription(e.target.value)}
                        />
                    </div>
                    <div>
                        <button type="submit" className="hover:shadow-form w-full rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none">
                            Book Appointment
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
