import React from 'react';

export default function PatientMedicalHistory({ user }) {
  const { name, appointments } = user;

  return (
    <div className="bg-white shadow-md rounded-lg px-6 py-8 md:w-3/4 lg:w-1/2 mx-auto">
      <h2 className="text-2xl font-bold mb-6">Medical History for {name}</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-8">
        {appointments.map((appointment, index) => (
          <div key={index} className="mb-8">
            <p className="text-lg font-semibold mb-2">Appointment {index + 1}</p>
            <p><span className="font-bold">Date:</span> {new Date(appointment.appointmentDate).toDateString()}</p>
            <p><span className="font-bold">Disease Category:</span> {appointment.diseaseCategory}</p>
            <p><span className="font-bold">Description:</span> {appointment.diseaseDescription}</p>
            <p><span className="font-bold">Period:</span> {appointment.diseasePeriod}</p>
            <p><span className="font-bold">Recommendation:</span> {appointment.recommendation}</p>
            <div className="mt-4">
              <p className="text-lg font-semibold mb-2">Prescriptions:</p>
              <ul>
                {appointment.prescriptions.map((prescription, index) => (
                  <li key={index} className="mb-4">
                    <p><span className="font-bold">Medicine Name:</span> {prescription.medicineName}</p>
                    <p><span className="font-bold">Dosage:</span> {prescription.dosage}</p>
                    <p><span className="font-bold">Frequency:</span> {prescription.frequency}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
