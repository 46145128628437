import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";


const MedicineComponent = ({ medicine, onBuyNowClick }) => {
  const imageUrl = `https://images.apollo247.in/pub/media${medicine.image}`;

  return (
    <article className="rounded-xl bg-white p-3 shadow-lg hover:shadow-xl hover:transform hover:scale-105 duration-300">
      <a href="#">
        <div className="relative flex items-end overflow-hidden rounded-xl">
          <img src={imageUrl} alt={medicine.name} />
          <div className="flex items-center space-x-1.5 rounded-lg bg-blue-500 px-4 py-1.5 text-white duration-100 hover:bg-blue-600">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="h-4 w-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
              />
            </svg>
            <button
              className="text-sm"
              onClick={() => onBuyNowClick(medicine)}
            >
              Add to cart
            </button>
          </div>
        </div>
        <div className="mt-1 p-2">
          <h2 className="text-slate-700">{medicine.name}</h2>
          <p className="mt-1 text-sm text-slate-400">{medicine.product_form}</p>
          <div className="mt-3 flex items-end justify-between">
            <p className="text-lg font-bold text-blue-500">
              {medicine.price}
            </p>
            <div className="flex items-center space-x-1.5 rounded-lg bg-blue-500 px-4 py-1.5 text-white duration-100 hover:bg-blue-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-4 w-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                />
              </svg>
              <button className="text-sm">Add to cart</button>
            </div>
          </div>
        </div>
      </a>
    </article>
  );
};

const PatientMedicineList = ({ category_id, onGoBack }) => {

  const navigate = useNavigate();
  const [medicines, setMedicines] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedMedicine, setSelectedMedicine] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pages, setPages] = useState(["1", "2", "3", "...", "8", "9", "10"]);
  const [currentPage, setCurrentPage] = useState(1);

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < pages.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    const fetchMedicines = async () => {
      try {
        const response = await fetch(
          "https://magento.apollo247.com/v1/CategoryProducts",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              category_id: category_id,
              page_id: currentPage,
              size: 24,
            }),
          }
        );
        const data = await response.json();
        if (data && data.products) {
          setMedicines(data.products);
          setTotalPages(data.total_pages);
        } else {
          setMedicines([]);
        }
        window.scrollTo({ top: 0, behavior: "smooth" });
      } catch (error) {
        console.error("Error fetching medicines:", error);
        setMedicines([]);
      }
    };

    fetchMedicines();
  }, [category_id, currentPage]);

  const handleBuyNowClick = (medicine) => {
    setSelectedMedicine(medicine);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedMedicine(null);
    setIsModalOpen(false);
  };

  function onGoBack() {
// refresh the page
    window.location.reload();

    } 

  const handleGoBack = () => {

    onGoBack();
  };

  return (
    <>
      <button
        onClick={handleGoBack}
        type="button"
        className="w-full flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:border-gray-700"
      >
        <svg
          className="w-5 h-5 rtl:rotate-180"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
          />
        </svg>
        <span>Go back</span>
      </button>
      {!isModalOpen && medicines && medicines.length > 0 && (
        <section className="py-10 bg-gray-100">
          <div className="mx-auto max-w-6xl grid gap-6 p-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
            {medicines.map((medicine, index) => (
              <MedicineComponent
                key={index}
                medicine={medicine}
                onBuyNowClick={handleBuyNowClick}
              />
            ))}
          </div>
        </section>
      )}
      {isModalOpen && selectedMedicine && (
        <div className="fixed inset-0 overflow-y-auto z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg max-w-xl w-full p-8 overflow-hidden">
            <button
              onClick={handleCloseModal}
              className="absolute top-3 right-3 text-white bg-red-500 p-1 rounded"
            >
              &#10005;
            </button>
            <h2 className="text-2xl font-bold mb-4">
              {selectedMedicine.name}
            </h2>
            <div className="flex justify-center mb-8">
              <img
                src={`https://images.apollo247.in/pub/media${selectedMedicine.image}`}
                alt={selectedMedicine.name}
                className="h-64 w-full object-cover"
              />
            </div>
            <p>Price: {selectedMedicine.price}</p>
            <p>SKU: {selectedMedicine.sku}</p>
            <p>Type: {selectedMedicine.type_id}</p>
            <p>Availability: {selectedMedicine.dc_availability}</p>
            <p>Is Express: {selectedMedicine.is_express}</p>
            <p>Is Contract: {selectedMedicine.is_in_contract}</p>
            <p>Merchandising Text: {selectedMedicine.merchandising_text}</p>
            <p>Product Form: {selectedMedicine.product_form}</p>
            <p>MOQ: {selectedMedicine.moq}</p>
            {/* Add more details as needed */}
          </div>
        </div>
      )}
      <div className="max-w-screen-xl mx-auto mt-12 px-4 text-gray-600 md:px-8">
        <div className="flex items-center justify-between text-sm text-gray-600 font-medium">
          <button
            onClick={goToPreviousPage}
            className="px-4 py-2 border rounded-lg duration-150 hover:bg-gray-50"
          >
            Previous
          </button>
          <div>
            Page {currentPage} of {pages.length}
          </div>
          <button
            onClick={goToNextPage}
            className="px-4 py-2 border rounded-lg duration-150 hover:bg-gray-50"
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default PatientMedicineList;
