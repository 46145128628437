import React, { useState, useEffect } from "react";
import DocDashboardAside from "../components/DocDashboardAside";
import DocDashboardNavbar from "../components/DocDashboardNavbar";
import DocDashbordPatientList from "../components/DocDashboardPatientList";
import DocDashbordPatientAnalytics from "../components/DocDashbordPatientAnalytics";
import DocDashboardMedicine from "../components/DocDashboardMedicine";

export default function DoctorDashboard() {
  // Define state to track current active section
  const [activeSection, setActiveSection] = useState("patientList");

  // Callback function to handle navigation
  const handleNavigation = (sectionName) => {
    setActiveSection(sectionName);
  };

  return (
    <>
      <DocDashboardAside
        onNavigation={handleNavigation}
        activeSection={activeSection} // Pass activeSection to DocDashboardAside
      />
      <div className="ml-auto mb-6 lg:w-[75%] xl:w-[80%] 2xl:w-[85%]">
        <DocDashboardNavbar />
        {activeSection === "patientList" && <DocDashbordPatientList />}
        {activeSection === "patientAnalytics" && (
          <DocDashbordPatientAnalytics />
        )}
        {activeSection === "medicines" && <DocDashboardMedicine />}
      </div>
    </>
  );
}
