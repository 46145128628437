import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Appointment from './components/Appointment';
import Home from './pages/Home';
import Login from './components/Login.js';
import Signup from './components/Signup.js';
import PatientDashboard from './pages/PatientDashboard';
import DoctorDashboard from './pages/DoctorDashboard.js';
import Print from './components/PrescriptionForm.js';
function App() {
  return (
    <div>
      <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/login' element={<Login />} />
        <Route exact path='/signup' element={<Signup />} />
        <Route exact path='/appointment' element={<Appointment />} />
        <Route exact path='/PatientDashboard' element={<PatientDashboard />} />
        <Route exact path='/DoctorDashboard' element={<DoctorDashboard />} />
        <Route exact path='/print' element={<Print />} />
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
