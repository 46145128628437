import React from 'react';

// Define service data
const servicesData = [
    {
      title: "Appointment Scheduling and Management",
      description: "Effortlessly manage your healthcare journey by scheduling and managing appointments with your preferred doctors. Our user-friendly platform ensures a seamless process, allowing you to choose convenient time slots and receive automated reminders.",
      imageUrl: "https://example.com/image1.jpg", // Replace with an actual image URL
      altText: "Appointment Scheduling Illustration",
      link: "#"
    },
    {
      title: "Digital Prescriptions and Secure QR Codes",
      description: "Experience the convenience of digital prescriptions generated by healthcare professionals. Our system ensures the security of your medical information by providing secure QR codes for prescribed medications, simplifying the dispensation process.",
      imageUrl: "https://example.com/image2.jpg", // Replace with an actual image URL
      altText: "Digital Prescriptions Illustration",
      link: "#"
    },
    {
      title: "Real-Time Analytics Dashboard",
      description: "Access valuable insights into your healthcare activities through our interactive dashboard. Track appointments, prescription history, and medication trends in real-time. Healthcare providers can make data-driven decisions to optimize services and enhance patient care.",
      imageUrl: "https://example.com/image3.jpg", // Replace with an actual image URL
      altText: "Analytics Dashboard Illustration",
      link: "#"
    }
  ];
  

export default function Services() {
  return (
    <div className="py-16 bg-purple-200">
      <div className="container m-auto px-6 text-gray-500 md:px-12 xl:px-0">
        <div className="mx-auto grid gap-6 md:w-3/4 lg:w-full lg:grid-cols-3">
          {servicesData.map((service, index) => (
            <div key={index} className="bg-white rounded-2xl shadow-xl px-8 py-12 sm:px-12 lg:px-8">
              <div className="mb-12 space-y-4">
                <h3 className="text-2xl font-semibold text-purple-900">{service.title}</h3>
                <p className="mb-6">{service.description}</p>
                <a href={service.link} className="block font-medium text-purple-600">Know more</a>
              </div>
              <img
                src={service.imageUrl}
                alt={service.altText}
                loading="lazy"
                className="w-full"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
