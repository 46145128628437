import React, { useState } from "react";
import MedicineList from "./DoctorMedicineList";

const Medicines = [
  {
    title: "Apollo Products",
    imageUrl: "https://images.apollo247.in/pub/media/catalog/category/apollo_products_1.jpg?tr=w-367.5,q-80,f-webp,dpr-1.25,c-at_max",
    category_id: "680",
  },
  {
    title: "Baby Care",
    imageUrl: "https://images.apollo247.in/pub/media/catalog/category/babycare_2.jpg?tr=w-367.5,q-80,f-webp,dpr-1.25,c-at_max",
    category_id: "24",
  },
  {
    title: "Skin Care",
    imageUrl: "https://images.apollo247.in/pub/media/catalog/category/skin_care_range.jpg?tr=w-367.5,q-80,f-webp,dpr-1.25,c-at_max",
    category_id: "15",
  },
  {
    title: "Glucometers & Test Strips",
    imageUrl: "https://images.apollo247.in/pub/media/catalog/category/glucometer_strips_1.jpg?tr=w-367.5,q-80,f-webp,dpr-1.25,c-at_max",
    category_id: "90",
  },
  {
    title: "Women Care",
    imageUrl: "https://images.apollo247.in/pub/media/catalog/category/womencare_2.jpg?tr=w-367.5,q-80,f-webp,dpr-1.25,c-at_max",
    category_id: "2051",
  },
  {
    title: "Health Devices",
    imageUrl: "https://images.apollo247.in/pub/media/catalog/category/healthdevices_2.jpg?tr=w-367.5,q-80,f-webp,dpr-1.25,c-at_max",
    category_id: "71",
  },
  {
    title: "Personal Care",
    imageUrl: "https://images.apollo247.in/pub/media/catalog/category/personalcare_2.jpg?tr=w-367.5,q-80,f-webp,dpr-1.25,c-at_max",
    category_id: "14",
  },
  {
    title: "Health Drinks & Supplements",
    imageUrl: "https://images.apollo247.in/pub/media/catalog/category/health_and_nutrition_2.jpg?tr=w-367.5,q-80,f-webp,dpr-1.25,c-at_max",
    category_id: "6",
  },
  {
    title: "Protein Powders & Drinks",
    imageUrl: "https://images.apollo247.in/pub/media/catalog/category/protein_supplements_1.jpg?tr=w-367.5,q-80,f-webp,dpr-1.25,c-at_max",
    category_id: "4064",
  },
  {
    title: "Multivitamins",
    imageUrl: "https://images.apollo247.in/pub/media/catalog/category/multi_vitamins.jpg?tr=w-367.5,q-80,f-webp,dpr-1.25,c-at_max",
    category_id: "8",
  },
  {
    title: "Ayurveda",
    imageUrl: "https://images.apollo247.in/pub/media/catalog/category/ayurvedic_range_1.jpg?tr=w-367.5,q-80,f-webp,dpr-1.25,c-at_max",
    category_id: "2273",
  },
  {
    title: "Sexual Wellness",
    imageUrl: "https://images.apollo247.in/pub/media/catalog/category/sexual_health_wellness_new.jpg?tr=w-367.5,q-80,f-webp,dpr-1.25,c-at_max",
    category_id: "3439",
  },
];

const MedicineComponent = ({ medicine, onClick }) => {
  return (
    <div
      className="card w-72 bg-base-100 shadow-xl flex flex-col justify-between mb-10 cursor-pointer"
      onClick={onClick}
    >
      <figure>
        <img src={medicine.imageUrl} alt={medicine.title} />
      </figure>
      <div className="card-body flex flex-col justify-between">
        <div className="flex items-center justify-center">
          <h2 className="card-title text-center">{medicine.title}</h2>
        </div>
      
      </div>
    </div>
  );
};


export default function DocDashboardMedicine() {
  const [showMedicineList, setShowMedicineList] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const toggleMedicineComponent = (category_id) => {
    setSelectedCategory(category_id);
    setShowMedicineList(true);
  };

  return (
    <div className="px-6 pt-6 2xl:container">
      {!showMedicineList && (
        <div className="flex flex-wrap justify-around">
          {Medicines.map((medicine, index) => (
            <MedicineComponent
              key={index}
              medicine={medicine}
              onClick={() => toggleMedicineComponent(medicine.category_id)}
            />
          ))}
        </div>
      )}
      {showMedicineList && <MedicineList category_id={selectedCategory} />}
    </div>
  );
}

