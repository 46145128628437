import React, { useState } from "react";
import MedicineList from "../components/PatientMedicineList";

export default function PatientMedicine() {

  const Medicines = [
    {
      title: "Apollo Products",
      imageUrl: "https://images.apollo247.in/pub/media/catalog/category/apollo_products_1.jpg?tr=w-367.5,q-80,f-webp,dpr-1.25,c-at_max",
      category_id: "680",
    },
    {
      title: "Baby Care",
      imageUrl: "https://images.apollo247.in/pub/media/catalog/category/babycare_2.jpg?tr=w-367.5,q-80,f-webp,dpr-1.25,c-at_max",
      category_id: "24",
    },
    {
      title: "Skin Care",
      imageUrl: "https://images.apollo247.in/pub/media/catalog/category/skin_care_range.jpg?tr=w-367.5,q-80,f-webp,dpr-1.25,c-at_max",
      category_id: "15",
    },
    {
      title: "Glucometers & Test Strips",
      imageUrl: "https://images.apollo247.in/pub/media/catalog/category/glucometer_strips_1.jpg?tr=w-367.5,q-80,f-webp,dpr-1.25,c-at_max",
      category_id: "90",
    },
    {
      title: "Women Care",
      imageUrl: "https://images.apollo247.in/pub/media/catalog/category/womencare_2.jpg?tr=w-367.5,q-80,f-webp,dpr-1.25,c-at_max",
      category_id: "2051",
    },
    {
      title: "Health Devices",
      imageUrl: "https://images.apollo247.in/pub/media/catalog/category/healthdevices_2.jpg?tr=w-367.5,q-80,f-webp,dpr-1.25,c-at_max",
      category_id: "71",
    },
    {
      title: "Personal Care",
      imageUrl: "https://images.apollo247.in/pub/media/catalog/category/personalcare_2.jpg?tr=w-367.5,q-80,f-webp,dpr-1.25,c-at_max",
      category_id: "14",
    },
    {
      title: "Health Drinks & Supplements",
      imageUrl: "https://images.apollo247.in/pub/media/catalog/category/health_and_nutrition_2.jpg?tr=w-367.5,q-80,f-webp,dpr-1.25,c-at_max",
      category_id: "6",
    },
    {
      title: "Protein Powders & Drinks",
      imageUrl: "https://images.apollo247.in/pub/media/catalog/category/protein_supplements_1.jpg?tr=w-367.5,q-80,f-webp,dpr-1.25,c-at_max",
      category_id: "4064",
    },
    {
      title: "Multivitamins",
      imageUrl: "https://images.apollo247.in/pub/media/catalog/category/multi_vitamins.jpg?tr=w-367.5,q-80,f-webp,dpr-1.25,c-at_max",
      category_id: "8",
    },
    {
      title: "Ayurveda",
      imageUrl: "https://images.apollo247.in/pub/media/catalog/category/ayurvedic_range_1.jpg?tr=w-367.5,q-80,f-webp,dpr-1.25,c-at_max",
      category_id: "2273",
    },
    {
      title: "Sexual Wellness",
      imageUrl: "https://images.apollo247.in/pub/media/catalog/category/sexual_health_wellness_new.jpg?tr=w-367.5,q-80,f-webp,dpr-1.25,c-at_max",
      category_id: "3439",
    },
  ];

  const [showMedicineList, setShowMedicineList] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const toggleMedicineComponent = (category_id) => {
    setSelectedCategory(category_id);
    setShowMedicineList(true);
  };

  return (
    <section className="py-10 bg-gray-100">
      {!showMedicineList && (
        <div className="mx-auto grid max-w-6xl  grid-cols-1 gap-6 p-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {/* Mapping Medicines to create medicine categories */}
          {Medicines.map((medicine, index) => (
            <article key={index} className="rounded-xl bg-white p-3 shadow-lg hover:shadow-xl hover:transform hover:scale-105 duration-300">
              <a href="#" onClick={() => toggleMedicineComponent(medicine.category_id)}>
                <div className="relative flex items-end overflow-hidden rounded-xl">
                  <img src={medicine.imageUrl} alt={medicine.title} />
                  <div className="flex items-center space-x-1.5 rounded-lg bg-blue-500 px-4 py-1.5 text-white duration-100 hover:bg-blue-600">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-4 w-4">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                    </svg>
                    <button className="text-sm">View Medicines</button>
                  </div>
                </div>
                <div className="mt-1 p-2">
                  <h2 className="text-slate-700">{medicine.title}</h2>
                  <p className="mt-1 text-sm text-slate-400">Category ID: {medicine.category_id}</p>
                </div>
              </a>
            </article>
          ))}
        </div>
      )}
      {showMedicineList && <MedicineList category_id={selectedCategory} />}
    </section>
  );
}
